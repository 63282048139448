@use "../../styles/vars";

.container {
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  overflow: auto;
}

.logo {
  width: 630px;
  height: 108px;
  @media (max-width: vars.$xs-max) {
    display: none;
  }
}

.title {
  margin-top: 30px;
  color: vars.$primary-color;
  font-size: 36px;
  font-weight: 300;

  @media (max-width: vars.$xs-max) {
    font-size: 25px;
  }
}

.list {
  display: flex;
  width: 100%;
  max-width: 800px;

  @media (max-width: vars.$xs-max) {
    display: block;
  }
}

.item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
  text-decoration: none;
  margin: 0 auto;

  @media (max-width: vars.$xs-max) {
    width: 100%;
    margin-bottom: 20px;
  }

  &:hover {
    .caption {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

.photo {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding-top: calc(472 / 380 * 100%);
  background-size: cover;
  background-position: center;

  @media (max-width: vars.$xs-max) {
    padding-top: calc(340 / 409 * 100%);
  }
}

.photoWpg {
  background-image: url('./wpg.jpg');
}

.photoLv {
  background-image: url('./lv.jpg');
}

.caption {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FF595C7D;
  color: #fff;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0;
  transform: translate(0,15%);
  transition: .5s transform, .5s opacity;
}

.itemTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  height: 70px;
  border: 3px solid vars.$primary-color;
  font-size: 20px;
  line-height: 1;
  font-weight: 900;
  letter-spacing: 2px;
  color: vars.$primary-color;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: vars.$xs-max) {
    position: relative;
    margin: -80px auto 0;
    height: 45px;
    font-size: 12px;
    background-color: #fff;
    width: 230px;
  }
}
